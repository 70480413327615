import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/users");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserBirthday = createAsyncThunk(
  "user/fetchUserBirthday",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/birthdays");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserTranscations = createAsyncThunk(
  "user/fetchUserTranscations",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/admin/transactions/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllTranscations = createAsyncThunk(
  "user/fetchAllTranscations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/get-transactions");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchVTUTranscations = createAsyncThunk(
  "user/fetchVTUTranscations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/vtutransactions");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const suspendUser = createAsyncThunk(
  "user/suspendUser",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiService.post("admin/user/suspend", formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiService.post("/admin/user/update", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserDetail = createAsyncThunk(
  "user/fetchUserDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/admin/view-user/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(`/admin/delete-user/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    userBirthdays: [],
    transactions: [],
    vtutransactions:[],
    allTranscations: [],
    status: "idle",
    allTranscationsStatus: "idle",
    allTranscationsError: null,
    transactionsStatus: "idle", // Specific status for transactions
    error: null,
    transactionsError: null, // Specific error for transactions
    userDetail: null,
    userDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.users = action.payload.data;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUserBirthday.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserBirthday.fulfilled, (state, action) => {
        state.status = "success";
        state.userBirthdays = action.payload.data;
      })
      .addCase(fetchUserBirthday.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Handling fetchUserTranscations
      .addCase(fetchUserTranscations.pending, (state) => {
        state.transactionsStatus = "loading";
        state.transactionsError = null;
      })
      .addCase(fetchUserTranscations.fulfilled, (state, action) => {
        state.transactionsStatus = "success";
        state.transactions = action.payload.data;
      })
      .addCase(fetchUserTranscations.rejected, (state, action) => {
        state.transactionsStatus = "failed";
        state.transactionsError = action.error.message;
      })

      .addCase(fetchAllTranscations.pending, (state) => {
        state.allTranscationsStatus = "loading";
        state.allTranscationsError = null;
      })
      .addCase(fetchAllTranscations.fulfilled, (state, action) => {
        state.allTranscationsStatus = "success";
        state.allTranscations = action.payload.data;
      })
      .addCase(fetchAllTranscations.rejected, (state, action) => {
        state.allTranscationsStatus = "failed";
        state.allTranscationsError = action.error.message;
      })

      .addCase(fetchVTUTranscations.pending, (state) => {
        state.status = "loading";
        state.allTranscationsError = null;
      })
      .addCase(fetchVTUTranscations.fulfilled, (state, action) => {
        state.status = "success";
        state.vtutransactions = action.payload.data;
      })
      .addCase(fetchVTUTranscations.rejected, (state, action) => {
        state.status = "failed";
        state.allTranscationsError = action.error.message;
      })

      .addCase(suspendUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(suspendUser.fulfilled, (state, action) => {
        state.status = "success";
        state.users = state.users?.map((user) =>
          user.id === action.payload.id ? action.payload : user
        );
      })
      .addCase(suspendUser.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })

      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "success";
        state.users = state.users?.map((user) =>
          user.id === action.payload.id ? action.payload : user
        );
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })

      .addCase(fetchUserDetail.pending, (state) => {
        state.userDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.userDetailStatus = "succeeded";
        state.userDetail = action.payload;
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.userDetailStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = "success";
        state.users = state.users?.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectUserDetail = (state) => state.user?.userDetail;
export const selectUserTransactions = (state) => state.user?.transactions;

export default userSlice.reducer;

import React, { useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import ModalPrivacy from "../../components/modals/legal/ModalPrivacy";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermsandPolicy } from "../../redux/slices/ceoSlice";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTermsandPolicy());
  }, [dispatch]);

  const termsandPolicies = useSelector((state) => state.ceo?.termsandPolicies);

  const filteredPolicies = termsandPolicies.policy;

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Privacy Policy
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Legal</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              Privacy Policy
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            // onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <div>
          <ModalPrivacy />
        </div>
      </div>
      <div>
        <textarea
          name=""
          id=""
          value={filteredPolicies}
          cols="20"
          rows="10"
          readOnly
          
          className="p-4 w-full rounded-lg border-2 border-gray-200"
        />
      
      </div>
    </div>
  );
};

export default PrivacyPolicy;

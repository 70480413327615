import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

import {
  fetchLaundryOrderDetail,
  selectLaundryOrderDetail,
} from "../../redux/slices/laundrySlice";
import {
  convertValue,
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { fetchUsers } from "../../redux/slices/userSlice";

const ItemsReceivedDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLaundryOrderDetail(id));
    dispatch(fetchUsers());
  }, [dispatch, id]);

  const laundryOrderDetail = useSelector(selectLaundryOrderDetail);

  const usersList = useSelector((state) => state.user?.users);
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const status = useSelector(
    (state) => state.laundry?.laundryOrderDetailStatus
  );
  const error = useSelector((state) => state.laundry?.error);

  const totalQuantity = laundryOrderDetail?.data?.order_details?.reduce(
    (total, product) => {
      return total + (product.quantity || 0); // Ensure quantity is a number and not null/undefined
    },
    0
  );

  const getCountryNameFromId = (countryId) => {
    const country = countries?.find(
      (country) => country.id === parseInt(countryId, 10)
    );
    return country ? country.name : "No country";
  };

  const getStateNameFromId = (stateId) => {
    const state = states?.find((state) => state.id === parseInt(stateId, 10));
    return state ? state.name : "No state";
  };

  const getCityNameFromId = (provinceId) => {
    const province = provinces?.find(
      (province) => province.id === parseInt(provinceId, 10)
    );
    return province ? province.name : "No city";
  };

  const getUserDetailFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return {
        country: "N/A",
        city: "N/A",
        state: "N/A",
        address: "N/A",
        email: "N/A",
      };
    }

    // Get the actual state and city names from their IDs
    const countryName = getCountryNameFromId(user.country); // Assuming user.state is stateId
    const stateName = getStateNameFromId(user.state); // Assuming user.state is stateId
    const cityName = getCityNameFromId(user.province); // Assuming user.province is provinceId

    return {
      city: cityName,
      state: stateName,
      country: countryName,
      address: user.address,
      email: user.email,
    };
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Item Received Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/tracks/items-received">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">
                Item Received
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Item Delivered Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {laundryOrderDetail ? (
            <div className="flex mt-8 gap-6">
              <div className="flex flex-col gap-6 w-5/12">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] whitespace-nowrap font-semibold tracking-[0.09px]">
                      Laundry Order Information
                    </h1>

                    <div className="flex flex-col  gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] whitespace-nowrap font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>

                        <p className=" text-base font-medium whitespace-nowrap">
                          {laundryOrderDetail.data.username}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Phone
                          </p>
                        </div>

                        <p className=" text-base font-medium whitespace-nowrap">
                          {laundryOrderDetail.data.phone}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Email
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                        {getUserDetailFromId(laundryOrderDetail.data.user_id).email}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Address
                          </p>
                        </div>

                        <p className=" text-base font-medium w-48 break-words text-right">
                          {getUserDetailFromId(laundryOrderDetail.data.user_id).address}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            City
                          </p>
                        </div>

                        <p className=" text-base font-medium w-48 break-words text-right">
                          {getUserDetailFromId(laundryOrderDetail.data.user_id).city}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            State
                          </p>
                        </div>

                        <p className=" text-base font-medium w-48 break-words text-right">
                          {getUserDetailFromId(laundryOrderDetail.data.user_id).state}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Country
                          </p>
                        </div>

                        <p className=" text-base font-medium w-48 break-words text-right">
                          {getUserDetailFromId(laundryOrderDetail.data.user_id).country}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Order Id
                          </p>
                        </div>

                        <p className=" text-base font-medium whitespace-nowrap">
                          {laundryOrderDetail.data.order_id}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Paid
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                          {convertValue(laundryOrderDetail.data.paid)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                          Pickup Date
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                          {formatCreatedAt(laundryOrderDetail.data.pickup_date)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Delivery Date
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                          {formatCreatedAt(
                            laundryOrderDetail.data.delivery_date
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Pick Up Method
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                          {laundryOrderDetail.data.pickup}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Drop Off Method
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                          {laundryOrderDetail.data.dropoff}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Price
                          </p>
                        </div>

                        <p className=" text-base font-medium whitespace-nowrap">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            laundryOrderDetail.data.price
                              ? laundryOrderDetail.data.price
                              : "0"
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Total
                          </p>
                        </div>

                        <p className=" text-base font-medium  whitespace-nowrap">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            laundryOrderDetail.data.total
                              ? laundryOrderDetail.data.total
                              : "0"
                          )}
                        </p>
                      </div>
                     
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Created
                          </p>
                        </div>
                        <p className="text-base font-medium whitespace-nowrap">
                          {formatCreatedAt(laundryOrderDetail.data.created_at)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[16px] font-medium tracking-[0.07px]">
                            Updated
                          </p>
                        </div>
                        <p className="text-base font-medium whitespace-nowrap">
                          {formatCreatedAt(laundryOrderDetail.data.updated_at)}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-col gap-2">
                          <p className="text-[16px] text-[#1A1C21] text-left font-medium tracking-[0.07px]">
                            Note
                          </p>
                        </div>

                        <textarea
                          name=""
                          id=""
                          value={
                            laundryOrderDetail.data.note
                              ? laundryOrderDetail.data.note
                              : " No note"
                          }
                          cols="30"
                          rows="4"
                          readOnly
                          className="text-[14px] w-full mt-2 border-2 rounded-lg text-[#1A1C21] font-medium"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 w-7/12">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex justify-between">
                      <div>
                        <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Order Details
                        </h1>
                      </div>
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-3 text-center">Item</th>
                            <th className="px-4 py-3 text-center">Qauntity</th>
                            <th className="px-4 py-3 text-center">Services</th>
                            <th className="px-4 py-3 text-center">Note</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-left">
                          {laundryOrderDetail.data.order_details?.map(
                            (product, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  <p> {product.item} </p>
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <p>{product.quantity}</p>
                                </td>

                                <td className="px-4 py-4 text-center">
                                  <p>{product.services.join(", ")}</p>
                                </td>
                                <td className="px-4 py-4 text-center">
                                  {product.note ? product.note : "No note"}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              className="px-4 py-4 text-center font-semibold"
                              colSpan="1"
                            >
                              Total Quantity
                            </td>
                            <td className="px-4 py-4 text-center font-semibold">
                              {totalQuantity}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          )}
        </div>
      )}
    </div>
  );
};

export default ItemsReceivedDetail;

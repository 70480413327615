import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { paywithPaystack } from "../redux/slices/paymentSlice";
import { toast } from "react-toastify";

const PaymentOptions = () => {
  const [selectedOption, setSelectedOption] = useState("manual");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextOption, setNextOption] = useState(null);

  const dispatch = useDispatch();

  const toggleOption = () => {
    // Open the modal and set the next option to be toggled
    setNextOption(selectedOption === "manual" ? "paystack" : "manual");
    setIsModalOpen(true);
  };

  const confirmToggle = async () => {
    setSelectedOption(nextOption);
    const res = await dispatch(paywithPaystack());
    toast.success(res.payload.message)
    setIsModalOpen(false);
  };

  const cancelToggle = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-between items-center">
      {/* Toggle Container */}
      <div
        onClick={toggleOption}
        role="switch"
        aria-checked={selectedOption === "manual"}
        className={`relative flex justify-center items-center w-44 h-12 rounded-3xl border-2 cursor-pointer transition-all duration-500 
          ${selectedOption === "manual" ? "bg-green-500" : "bg-blue-300"} 
          border-gray-300`}
      >
        {/* Placeholder Text */}
        <div
          className={`absolute flex justify-between items-center w-full px-2 text-sm transition-opacity duration-500 ${
            selectedOption === "manual" ? "opacity-100" : "opacity-0"
          }`}
        >
          <span className="text-transparent">Manual</span>
          <span className="text-gray-50">Paystack</span>
        </div>
        <div
          className={`absolute flex justify-between items-center w-full px-2 text-sm transition-opacity duration-500 ${
            selectedOption === "paystack" ? "opacity-100" : "opacity-0"
          }`}
        >
          <span className="text-gray-50">Manual</span>
          <span className="text-transparent">Paystack</span>
        </div>

        {/* Sliding Toggle Button */}
        <span
          className={`absolute transition-all duration-500 h-8 w-20 bg-white rounded-2xl font-semibold flex items-center justify-center
            ${selectedOption === "manual" ? "left-1" : "left-20"}`}
        >
          {selectedOption === "manual" ? "Manual" : "Paystack"}
        </span>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">
              Do you want to switch to{" "}
              {nextOption === "manual" ? "Manual" : "Paystack"}?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={cancelToggle}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                onClick={confirmToggle}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentOptions;

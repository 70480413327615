import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchCeoNote = createAsyncThunk(
  "ceo/fetchCeoNote",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/ceo-note", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTermsandPolicy = createAsyncThunk(
  "ceo/fetchTermsandPolicy",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/termsandpolicy", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCondition = createAsyncThunk(
  "ceo/updateCondition",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/update/conditions",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePrivacy = createAsyncThunk(
  "ceo/updatePrivacy",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/update/privacy",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCeoNote = createAsyncThunk(
  "ceo/addCeoNote",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/update/ceo-note",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const ceoSlice = createSlice({
  name: "ceo",
  initialState: {
    ceoNotes: [],
    termsandPolicies: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCeoNote.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCeoNote.fulfilled, (state, action) => {
        state.status = "success";
        state.ceoNotes = action.payload.data;
      })

      .addCase(fetchCeoNote.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTermsandPolicy.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTermsandPolicy.fulfilled, (state, action) => {
        state.status = "success";
        state.termsandPolicies = action.payload.data;
      })

      .addCase(fetchTermsandPolicy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addCeoNote.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addCeoNote.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ceoNotes = [action.payload];
      })
      .addCase(addCeoNote.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCondition.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCondition.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(updateCondition.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePrivacy.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updatePrivacy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(updatePrivacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default ceoSlice.reducer;

import React, { useEffect, useState } from "react";

import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";

import { useDispatch, useSelector } from "react-redux";

import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  fetchMarketDetail,
  selectMarketDetail,
} from "../../redux/slices/marketSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import AddProductMarket from "../../components/modals/product/AddProductMarket";
import ModalProductUpdateMarket from "../../components/modals/product/ModalProductUpdateMarket";
import ModalProductDeleteMarket from "../../components/modals/product/ModalProductDeleteMarket";

import MarketProductDetail from "./MarketProductDetail";
import TransferProductMarket from "../../components/modals/product/TransferProductMarket";
import MarketDiscountPrice from "../../components/modals/product/MarketDiscountPrice";
import MarketIncrementPrice from "../../components/modals/product/MarketIncrementPrice";
import { allProduct } from "../../redux/slices/productSlice";
import OutOfStock from "../../components/modals/product/OutOfStock";
import UpdateDuplicateProductMarket from "../../components/modals/product/UpdateDuplicateProductMarket";

const MarketDetail = () => {
  const { id } = useParams();

  const [search, setSearch] = useState("");
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );

  const owner_type = "market";

  useEffect(() => {
    const page = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(page);
  }, [searchParams]);

  const dispatch = useDispatch();
  const marketDetail = useSelector(selectMarketDetail);

  const status = useSelector((state) => state.market?.marketDetailStatus);
  const error = useSelector((state) => state.market?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  useEffect(() => {
    dispatch(fetchMarketDetail({ id, page: currentPage }));
    dispatch(allProduct({ id, owner_type }));
  }, [dispatch, id, currentPage]);

  const allProducts = useSelector((state) => state.product?.allProducts);
  const statusp = useSelector((state) => state.product?.status);

  // Filter categories based on search term
  const filteredProducts = allProducts?.filter((product) =>
    search.toLowerCase() === ""
      ? product
      : product?.name?.toLowerCase().includes(search)
  );

  const itemsPerPage = 14; // Number of items to display per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentProductData = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const nextPage = () => {
    setSearchParams({ page: currentPage + 1 });
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setSearchParams({ page: currentPage - 1 });
    }
  };

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);
    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  const handleCheckboxChange = (e, productId) => {
    const isChecked = e.target.checked;

    setSelectedProductIds((prevSelectedIds) => {
      if (isChecked) {
        // Add the productId to the selectedProductIds array
        return [...prevSelectedIds, productId];
      } else {
        // Remove the productId from the selectedProductIds array
        return prevSelectedIds.filter((id) => id !== productId);
      }
    });
  };

  // Handle the "select all" checkbox
  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setSelectAll(isChecked); // Update the selectAll state first

    if (isChecked) {
      // If checked, select all product IDs
      const allProductIds = allProducts?.map((product) => product.id);
      setSelectedProductIds(allProductIds);
    } else {
      // If unchecked, clear selectedProductIds
      setSelectedProductIds([]);
    }
  };



  const clearSelectedProductIds = () => {
    setSelectedProductIds([]);
    setSelectAll(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Market Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Store</p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/shopping/markets">
              <p className="text-[14px] text-[#1F7BF4] font-medium">Markets</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Market Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="12" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {marketDetail ? (
            <>
              <div className="flex my-4 gap-6">
                <div className="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                        Market Information
                      </h1>

                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                              Name
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {marketDetail.data.market.name}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Landmark
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {marketDetail.data.market.landmark}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Location
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {marketDetail.data.market.location}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Status
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {marketDetail.data.market.type}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Start Date
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {formatCreatedAt(
                              marketDetail.data.market.created_at
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Updated Date
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {formatCreatedAt(
                              marketDetail.data.market.updated_at
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Country
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {getCountryNameById(
                              marketDetail.data.market.country
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              State
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {getStateNameById(marketDetail.data.market.state)}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              City
                            </p>
                          </div>
                          <p className="text-base font-semibold whitespace-nowrap">
                            {getCityNameById(marketDetail.data.market.province)}
                          </p>
                        </div>

                        <div className="flex justify-between gap-4">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Note
                            </p>
                          </div>
                          <textarea
                            name=""
                            id=""
                            cols="10"
                            rows="5"
                            readOnly
                            value={marketDetail.data.market.note}
                            className=" w-1/2 text-[#1A1C21] text-base border-2 rounded-lg p-2 tracking-[0.07px]"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex justify-between">
                      <div className="flex justify-between items-center gap-2">
                        <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Products
                        </h1>

                        <button className="relative w-72">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <HiOutlineSearch className=" text-gray-500 text-2xl" />
                          </div>
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                            placeholder="Search product..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </button>
                      </div>

                      <div className=" flex justify-between items-center gap-4">
                        <AddProductMarket
                          marketId={id}
                          country={marketDetail.data.market.country}
                          city={marketDetail.data.market.province}
                          state={marketDetail.data.market.state}
                          page={currentPage}
                        />

                        <UpdateDuplicateProductMarket
                          owner_id={id}
                          page={currentPage}
                          clearSelectedProductIds={clearSelectedProductIds}
                          selectedProductIds={selectedProductIds}
                          country={marketDetail.data.market.country}
                          city={marketDetail.data.market.province}
                          state={marketDetail.data.market.state}
                        />
                        
                        {allProducts?.length === 0 ? (
                          ""
                        ) : (
                          <TransferProductMarket
                            owner_id={id}
                            page={currentPage}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                            country={marketDetail.data.market.country}
                            city={marketDetail.data.market.province}
                            state={marketDetail.data.market.state}
                          />
                        )}

                        {allProducts?.length === 0 ? (
                          ""
                        ) : (
                          <MarketDiscountPrice
                            marketId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                            page={currentPage}
                          />
                        )}

                        {allProducts?.length === 0 ? (
                          ""
                        ) : (
                          <MarketIncrementPrice
                            marketId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                            page={currentPage}
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-4 text-center">No.</th>
                            <th className="px-12 py-4 text-center">Image</th>
                            <th className="px-4 py-4 text-center">Name</th>

                            <th className="px-4 py-4 text-center">Category</th>
                            <th className="px-4 py-4 text-center">
                              Description
                            </th>
                            <th className="px-4 py-4 text-center">Type</th>
                            <th className="px-4 py-4 text-center">Price</th>
                            <th className="px-4 py-4 text-center">
                              Status-Stock
                            </th>

                            <th className="px-8 py-4">Created Date</th>
                            <th className="px-8 py-4">Updated Date</th>
                            <th className="px-2 py-4 text-center">
                              <div className=" flex justify-center items-center gap-2">
                                <h1>Action</h1>
                                {allProducts?.length === 0 ? (
                                  ""
                                ) : (
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleHeaderCheckboxChange}
                                    className="w-4 h-4"
                                  />
                                )}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm">
                          {statusp === "loading" ? (
                            <tr>
                              <td colSpan="12" className="text-center py-4">
                                <div className="flex justify-center items-center">
                                  <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                                </div>
                              </td>
                            </tr>
                          ) : currentProductData?.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Data
                              </td>
                            </tr>
                          ) : (
                            currentProductData?.map((product, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-4">
                                  <div className="flex justify-center items-center">
                                    <p className=" text-base text-center font-semibold ">
                                      {indexOfFirstItem + index + 1}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-2 py-2">
                                  <div className="flex justify-center p-2 items-center">
                                    <img
                                      src={
                                        product.image &&
                                        product.image.length > 0
                                          ? product.image[0]
                                          : "default-image-url.jpg"
                                      }
                                      alt="product"
                                      className="w-24 h-24 object-cover rounded-lg"
                                    />
                                  </div>
                                </td>
                                <td className="px-4 py-4">
                                  <div className="p-2">
                                    <p className="text-gray-500 text-center w-48 breake-words ">
                                      {product.name}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-center p-8 whitespace-nowrap">
                                  <p> {product.category}</p>
                                </td>
                                <td className="px-14 py-4">
                                  <p className="w-48 break-words text-center">
                                    {product.description
                                      ? product.description
                                      : "No description"}
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <p className=" whitespace-nowrap">
                                    {product.type}
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <p>
                                    <span className="mr-1">&#8358;</span>
                                    {formatNumberWithCommas(product.price)}
                                  </p>
                                </td>

                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  <div className=" flex justify-center">
                                    <OutOfStock
                                      name={product.name}
                                      pId={product.id}
                                      id={id}
                                    />
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.created_at)}
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.updated_at)}
                                </td>
                                <td className="px-4 py-4">
                                  <div className="flex gap-[8px] justify-center items-center">
                                    <div>
                                      <MarketProductDetail
                                        images={product.image}
                                        category={product.category}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        color={product.color}
                                        size={product.size}
                                        country={
                                          product.country
                                            ? getCountryNameById(
                                                product.country
                                              )
                                            : "No Info"
                                        }
                                        city={
                                          product.city
                                            ? getCityNameById(product.city)
                                            : "No Info"
                                        }
                                        state={
                                          product.state
                                            ? getStateNameById(product.state)
                                            : "No Info"
                                        }
                                        created_at={formatCreatedAt(
                                          product.created_at
                                        )}
                                        updated_at={formatCreatedAt(
                                          product.updated_at
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <ModalProductUpdateMarket
                                        id={id}
                                        pId={product.id}
                                        image={product.image}
                                        category={product.category}
                                        name={product.name}
                                        owner_id={product.owner_id}
                                        quantity={product.quantity}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        country={product.country}
                                        city={product.city}
                                        state={product.state}
                                        page={currentPage}
                                      />
                                    </div>
                                    <div>
                                      <ModalProductDeleteMarket
                                        id={id}
                                        pId={product.id}
                                        image={product.image}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        page={currentPage}
                                      />
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        value={product.id}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, product.id)
                                        }
                                        className="w-4 h-4"
                                        checked={selectedProductIds.includes(
                                          product.id
                                        )}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex justify-between px-[24px] py-[15px] items-center">
                      <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
                        Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
                        {filteredProducts?.length}
                      </p>

                      {/* Previous and Next Button */}
                      <div className="flex gap-2">
                        {/* Previous Button */}
                        <button
                          onClick={prevPage}
                          disabled={currentPage === 1} // Disable if on the first page
                          className={`px-4 py-2 rounded-md transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-300
                            ${
                              currentPage === 1
                                ? "bg-gray-300 cursor-not-allowed"
                                : "bg-blue-500 hover:bg-blue-600 active:bg-blue-700"
                            }  // Active button style
                          `}
                        >
                          Previous
                        </button>

                        {/* Next Button */}
                        <button
                          onClick={nextPage}
                          disabled={indexOfLastItem === totalPages}
                          className={`px-4 py-2 rounded-md ${
                            indexOfLastItem === totalPages
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          )}
        </div>
      )}
    </div>
  );
};

export default MarketDetail;
